import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

import MapContext from '../../MapPage/MapContext';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import ModalLite from '../../Modal/ModalLite';
import ModalTableInfo from '../../Modal/ModalTableInfo';
import ModalHeader from '../../Modal/ModalHeader';
import FloorPlanImagesWidget from '../../FloorPlan/FloorPlanImagesWidget';
import VirtualTourDetail from '../../VirtualTour/VirtualTourDetail';
import VideoDetail from '../../Video/VideoDetail';
import FinishCollection from '../../FinishCollection/FinishCollection';
import FurnitureConfigurator from '../../FurnitureConfigurator/FurnitureConfigurator';
import PhotoGalleryDetail from '../../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import Favorite from '../../Personalization/Favorite/Favorite';
import { bedBathSqftArray } from '../../../utils/common';

import './FloorPlansItems.scss';

const FloorPlansItem = ( {
  data,
  showPricing,
  showSoldOut,
  remainingInventory,
} ) => {
  const [ modalIsOpen, setModalIsOpen ] = useState( false );
  const [ inventory, setInventory ] = useState( 'Sold Out' );

  const {
    id,
    title,
    description,
    studio,
    floorplan2d,
    floorplan3d,
    bed,
    bath,
    roomData,
    roomFees,
    sqft,
    threeSixties,
    furnitureConfiguratorUrl,
    finishCollection,
    levelsOfCare,
    video,
    photoGalleryImages,
    withDen,
  } = data;

  const {
    personalizationActive,
    setUnitHasDisplayableFees,
  } = useContext( ConfigContext );

  const { activeLevelOfCareUuid } = useContext( MapContext );

  // If the unit being viewed has fees, set this variable in global context.
  // We're using this to determine whether or not to show the 'more' cta
  // that allows the user to see fee information on a unit.
  useEffect( () => {
    if ( roomFees?.length > 0 && showPricing ) {
      setUnitHasDisplayableFees( true );
    }
  }, [ roomFees, showPricing ] );

  useEffect( () => {
    if ( remainingInventory > 0 && remainingInventory <= 3 ) {
      setInventory( `${remainingInventory} Left` );
    }
  }, [ remainingInventory ] );

  // fp button stuff
  const FloorPlansItemCoverImage = () => {
    let floorPlansItemCoverImage = null;
    if ( floorplan3d || floorplan2d ) {
      floorPlansItemCoverImage = (
        <div
          className="imgHolder"
          style={{
            backgroundImage: `url(${
              floorplan3d?.oneThirdScreen || floorplan2d?.oneThirdScreen
            })`,
          }}
        />
      );
    } else {
      floorPlansItemCoverImage = (
        <div className="imgHolder imgHolder--noImage">
          No Floor Plan Image Available
        </div>
      );
    }
    return floorPlansItemCoverImage;
  };

  // modal stuff
  const navBtns = [];

  if ( description ) {
    navBtns.push( {
      section: 'info-scroll',
      text: 'Info',
    } );
  }

  if ( floorplan3d || floorplan2d ) {
    navBtns.push( {
      section: 'floorplan-scroll',
      text: 'Floor Plan',
    } );
  }

  if ( roomData?.length > 0 ) {
    navBtns.push( {
      section: 'dimensions-scroll',
      text: 'Dimensions',
    } );
  }

  if ( photoGalleryImages?.length > 0 ) {
    navBtns.push( {
      section: 'slideshow-scroll',
      text: 'Photos',
    } );
  }

  if ( video ) {
    navBtns.push( {
      section: 'video-scroll',
      text: 'Video',
    } );
  }

  if ( threeSixties?.length > 0 ) {
    navBtns.push( {
      section: 'tour-scroll',
      text: 'Tour',
    } );
  }

  if ( furnitureConfiguratorUrl ) {
    navBtns.push( {
      section: 'configurator-scroll',
      text: 'Furniture Layout',
    } );
  }

  if ( finishCollection && Object.keys( finishCollection ).length ) {
    navBtns.push( {
      section: 'finishes-scroll',
      text: 'Finishes',
    } );
  }

  const bedBathSqft = bedBathSqftArray( studio, bed, bath, sqft, withDen );

  const floorPlanModalHeader = () => {
    const modalTitle = {
      title,
      tags: [],
      bedBathSqft,
    };

    if ( showSoldOut && remainingInventory <= 3 ) {
      modalTitle.tags.push(
        {
          type: 'soldOut',
          text: inventory,
        },
      );
    }

    return (
      <ModalHeader
        title={modalTitle}
        navBtns={navBtns}
        handleModalHide={() => setModalIsOpen( false )}
      />
    );
  };

  const openModal = useCallback( () => {
    setModalIsOpen( true );
    // If the unit being viewed has fees, set this variable in global context.
    // We're using this to determine whether or not to show the 'more' cta
    // that allows the user to see fee information on a unit.
    if ( roomFees.length ) {
      setUnitHasDisplayableFees( true );
    } else {
      setUnitHasDisplayableFees( false );
    }
  }, [ roomFees, id ] );

  return activeLevelOfCareUuid === levelsOfCare ? (
    <>
      <ModalLite
        ModalHeader={floorPlanModalHeader}
        isOpen={modalIsOpen}
        handleModalHide={() => setModalIsOpen( false )}
      >

        { description && (
          <ModalTableInfo
            id="info-scroll"
            tableInfo={null}
            tableDescription={description}
          />
        )}

        { roomFees?.length > 0 && showPricing && (
          <ModalTableInfo
            id="fees-scroll"
            sectionClassName="modalSection__displayFees"
            tableInfo={roomFees}
            tableDescription={null}
          />
        )}

        <FloorPlanImagesWidget
          className="floorplansContainer modalSection"
          threeD={floorplan3d?.modal}
          twoD={floorplan2d?.modal}
          name={title}
        />

        { roomData?.length > 0 && (
          <ModalTableInfo
            id="dimensions-scroll"
            tableInfo={roomData}
          />
        )}

        { photoGalleryImages?.length > 0 && (
          <PhotoGalleryDetail
            id="slideshow-scroll"
            uuid={null}
            images={photoGalleryImages}
            galleryClass="slideshow modalSection"
            imageClass="slideshowPhoto"
          />
        )}

        <VideoDetail
          hasId
          videoId={video?.videoId}
          service={video?.service}
          autoplay={false}
        />

        { threeSixties?.length > 0 && (
          <VirtualTourDetail
            id="tour-scroll"
            uuid={null}
            threeSixtiesData={threeSixties}
            className="modalSection modalSection--sixteen9"
          />
        )}

        <FurnitureConfigurator
          id="configurator-scroll"
          url={furnitureConfiguratorUrl}
          className="modalSection modalSection--sixteen9 modalSection--border"
        />

        <FinishCollection
          id="finishes-scroll"
          finishCollection={finishCollection}
          className="modalSection"
        />

      </ModalLite>

      <div className="gridItem">
        <button
          className="modalOpen gridItemTrigger"
          onClick={openModal}
          type="button"
        >
          <div className="imgContainer">
            <FloorPlansItemCoverImage />

            { showSoldOut && remainingInventory <= 3 && (
              <div className="gridItemBanner">
                <span className="gridItemBanner__text">{ inventory }</span>
              </div>
            )}

          </div>
        </button>

        { personalizationActive && (
          <Favorite type="floor_plan" uuid={id} hasShadow={false} />
        )}

        <div className="floorplanInfo">
          <h2 className="type-display-xl">{title}</h2>

          { bedBathSqft?.length > 0 && (
            <h3 className="floorplanInfo__subtitle">
              { bedBathSqft.map( ( item, i ) => (
                <span
                  key={i}
                  className="floorplanInfo__subtitle__group"
                >
                  {item}
                </span>
              ) )}
            </h3>
          )}

        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default FloorPlansItem;
